import React from 'react';

const ChangeFont = ({ formData, onChange, schema }) => {
    const fonts = ['Inter','Arial', 'Courier New', 'Georgia', 'Times New Roman', 'Verdana'];

    const handleFontChange = (event) => {
        const selectedFont = event.target.value;
        onChange(selectedFont); // Notify the parent form about the change
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <label>{schema.title}</label>
            <select value={formData || fonts[0]} onChange={handleFontChange}>
                {fonts.map((font) => (
                    <option key={font} value={font}>
                        {font}
                    </option>
                ))}
            </select>
            <div style={{ fontFamily: formData || fonts[0], padding: '0.5rem' }}>
                This is the selected font family
            </div>
        </div>
    );
};

export default ChangeFont;