import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { FormGroup } from 'reactstrap';
import { useDebouncedEffect } from '../../hooks/useDebounceEffect';
import { HexColorPicker } from 'react-colorful';

export default function InputColorPicker(props) {
  const {
    formData,
    onChange,
    required,
    idSchema: { $id },
    schema: { title },
  } = props;

  const [current, setCurrent] = useState(formData);
  const [showPicker, setShowPicker] = useState(false);

  useDebouncedEffect(
    () => {
      if (current !== formData) {
        console.log('Color changed to :', current);
        onChange(current);
      }
    },
    [current],
    450
  );

  useEffect(() => {
    setCurrent(formData);
  }, [formData]);

  const pickerRef = useClickOutsideElementDetection(showPicker, () => {
    setShowPicker(false);
  }, [setShowPicker]);

  const handleColorSwatchClick = useCallback(() => {
    setShowPicker(showPicker => !showPicker);
  }, [setShowPicker]);

  const setColor = useCallback((color) => {
    const normalized = normalizeColor(color);
    setCurrent(normalized);
  }, [normalizeColor, setCurrent]);

  const pickScreenColor = useCallback(async () => {
    if ('EyeDropper' in window) {
      const eyeDropper = new window.EyeDropper();
      try {
        const result = await eyeDropper.open();
        setColor(normalizeColor(result.sRGBHex));
        setShowPicker(false); // Close the picker after selecting a screen color
      } catch (error) {
        console.error('EyeDropper canceled or failed:', error);
      }
    } else {
      alert('Your browser does not support the EyeDropper API.');
    }
  }, [setColor, normalizeColor, setShowPicker]);

  return (
  <FormGroup>
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <label className="control-label" htmlFor={$id}>
        {title}
        {required ? <span className="required">*</span> : null}
      </label>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        {/* Color Preview Box */}
        <ColorSwatch color={current} onClick={handleColorSwatchClick} />
        {/* HEX Input Field */}
        <HexColorInput value={current} onChange={setColor} />
      </div>
      {showPicker && (
        <div
          ref={pickerRef}
          style={{
            position: 'absolute',
            zIndex: 1000,
            background: '#fff',
            padding: '1rem',
            border: '1px solid #ccc',
            borderRadius: '8px',
          }}
        >
          <HexColorPicker color={current || '#a19c9c'} onChange={setColor} />
          {/* HEX Input Field Inside the Picker */}
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0.5rem', textAlign: 'center' }}>
            <ColorSwatch size={40} color={current} />
            <HexColorInput value={current} onChange={setColor} />
          </div>
          <button
            type="button"
            onClick={pickScreenColor}
            style={{
              marginTop: '0.5rem',
              padding: '0.5rem 1rem',
              background: '#007bff',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Pick Screen Color
          </button>
        </div>
      )}
    </div>
  </FormGroup>
  );
}


function useClickOutsideElementDetection(isActive, onClickOutsideElement, dependencies) {
  const elementRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (elementRef.current && !elementRef.current.contains(event.target)) {
        onClickOutsideElement(event);
      }
    };

    if (isActive) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActive, ...(dependencies || [])]);

  return elementRef;
}


function HexColorInput({
  value,
  onChange
}) {
  const [hexInput, setHexInput] = useState(value);
  useEffect(() => {
    if (value != hexInput) setHexInput(value);
  }, [value]);

  const handleHexInputChange = (e) => {
    const value = e.target.value;
    setHexInput(value);

    // Validate HEX code and update the color picker
    if (/^#([0-9A-Fa-f]{3}){1,2}$/.test(value)) {
      onChange(value);
    }
  };

  return (
    <input
      type="text"
      value={hexInput}
      placeholder="(not set)"
      onChange={handleHexInputChange}
      style={{
        padding: '0.5rem',
        border: '1px solid #ccc',
        borderRadius: '4px',
        width: '120px',
        textAlign: 'center',
      }}
    />
  )  
}


function ColorSwatch({
  size = '2.1em',
  color,
  placeholderColor = '#a19c9c',
  onClick,
}) {
  const [width, height] = useMemo(() => {
    if (Number.isFinite(size)) {
      size = `${(size) | 0}px`;
    }
    return [size, size]
  }, [size])
  return (
    <div
      style={{
        width,
        height,
        backgroundColor: color || placeholderColor || '#a19c9c',
        border: '1px solid #ccc',
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
    />
  )
}


let offscreenCanvas = null;
function normalizeColor(color) {
    if (!offscreenCanvas) {
      offscreenCanvas = document.createElement("canvas");
    }

    let ctx = offscreenCanvas.getContext("2d");
    ctx.fillStyle = color;
    let computedColor = ctx.fillStyle;
    
    if (!computedColor) {
        throw new Error("Invalid color format");
    }
    
    ctx.fillRect(0, 0, 1, 1);
    let imageData = ctx.getImageData(0, 0, 1, 1).data;
    
    function componentToHex(c) {
        let hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }
    
    return `#${componentToHex(imageData[0])}${componentToHex(imageData[1])}${componentToHex(imageData[2])}`;
}